import Par1 from "../assets/images/partners/1.png";
import Par2 from "../assets/images/partners/2.png";
import Par3 from "../assets/images/partners/3.png";
import Par4 from "../assets/images/partners/4.png";

const Partners = [
  {
    srcImage: Par1,
  },
  {
    srcImage: Par2,
  },
  {
    srcImage: Par3,
  },
  {
    srcImage: Par4,
  },
];

export default Partners;

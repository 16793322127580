import Blog1 from "../assets/images/blog/1.jpg";
import Blog2 from "../assets/images/blog/2.jpg";
import Blog3 from "../assets/images/blog/3.jpg";
import Author1 from "../assets/images/blog/blog-author-1.png";
import Author2 from "../assets/images/blog/blog-author-2.png";
import Author3 from "../assets/images/blog/blog-author-3.png";

const Blog = [
  {
    BlogImage: Blog1,
    AuthorImage: Author1,
    AuthorName: "Jhon Doe",
    numberOfComments: "8 Comments",
    titleEn: "How to choose a perfect software for your business",
    titleFr:
      "This text is an example of a text that can be replaced in the same space",
  },
  {
    BlogImage: Blog2,
    AuthorImage: Author2,
    AuthorName: "Jhon Doe",
    numberOfComments: "10 Comments",
    titleEn: "How to choose a perfect software for your business",
    titleFr:
      "This text is an example of a text that can be replaced in the same space",
  },
  {
    BlogImage: Blog3,
    AuthorImage: Author3,
    AuthorName: "Jhon Doe",
    numberOfComments: "02 Comments",
    titleEn: "How to choose a perfect software for your business",
    titleFr:
      "This text is an example of a text that can be replaced in the same space",
  },
];

export default Blog;

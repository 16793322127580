import OwlCarousel from "react-owl-carousel";
import { IoLanguage, IoChevronDownOutline } from "react-icons/io5";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo/hero-logo.png";
import videoImage from "../assets/images/video/1.png";
import About1 from "../assets/images/about/1.png";
import About2 from "../assets/images/about/2.png";
import Feature from "../data/Feature";
import Partners from "../data/Partners";
import Team from "../data/Team";
import Blog from "../data/Blog";
import { LANGUAGES } from "../i18n/index";
import { useTranslation } from "react-i18next";
import Cursor from "../components/Cursor";
import ScrollUp from "../components/ScrollUp";
import MagnificPopup from "../components/MagnificPopup";

const Home = () => {
  const { i18n, t } = useTranslation();

  const [isSidebarActive, setIsSidebarActive] = useState(false);

  const [isLang, setIsLang] = useState(false);
  const handleLang = () => {
    setIsLang(!isLang);
  };

  const handleSlider = () => {
    setIsSidebarActive(!isSidebarActive);
  };

  const Pages = ["home", "feature", "about", "partners", "team"];

  const options = {
    loop: true,
    margin: 10,
    nav: true,
    autoplay: true,
    responsive: {
      0: {
        items: 4,
      },
      600: {
        items: 5,
      },
      1000: {
        items: 7,
      },
    },
  };

  const [isHeaderScroll, setIsHeaderScroll] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;

      // Check if scrolling up, scrolling down, or at the top
      if (currentScrollPos < prevScrollPos) {
        setIsHeaderScroll(true);
      } else {
        setIsHeaderScroll(false);
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  return (
    <>
      <Cursor />

      <header id="header" className={`${!isHeaderScroll ? "active" : ""}`}>
        <div className="container">
          <div className="brand-logo">
            <img src={logo} alt="" />
          </div>

          <nav className="navbar-nav">
            <ul className="nav-items">
              {Pages.map((page, index) => (
                <li className="nav-item smooth-menu" key={index}>
                  <a href={`#${page}`}>{t(`navbar.${page}`)}</a>
                </li>
              ))}
            </ul>
          </nav>
          <div className="nav-footer-btns">
            <a href="#" className="custom-btn bx-btn">
              <span>{t(`navbar.quotes`)}</span>
            </a>
            <div className="drop-down-language-menu" onClick={handleLang}>
              <div className="dropdown language-btn">
                <span className="icon is-small">
                  <IoLanguage size={20} color="#33a2d4" />
                </span>
                <span>{t("navbar.language")}</span>
                <span className="icon is-small">
                  <IoChevronDownOutline size={20} color="#777" />
                </span>
              </div>
              <div className={`dropdown-menu-lg ${isLang ? "active" : ""}`}>
                {LANGUAGES.map((language, index) => (
                  <div
                    className={`dropdown-item ${language.code}-button `}
                    data-lang={language.code}
                    key={index}
                    onClick={() => i18n.changeLanguage(language.code)}
                  >
                    {language.label}
                  </div>
                ))}
              </div>
            </div>
            <div
              className="burger-icon"
              id="burger-icon"
              onClick={handleSlider}
            >
              <svg
                width="35"
                height="35"
                viewBox="0 0 32 42"
                xmlns="http://www.w3.org/2000/svg"
                className={`${isSidebarActive ? "active" : ""}`}
              >
                <g transform="matrix(1,0,0,1,-389.5,-264.004)">
                  <g id="arrow_left2">
                    <g transform="matrix(1,0,0,1,0,5)">
                      <path
                        id="top"
                        d="M390,270L420,270L420,270C420,270 420.195,250.19 405,265C389.805,279.81 390,279.967 390,279.967"
                      />
                    </g>
                    <g transform="matrix(1,1.22465e-16,1.22465e-16,-1,0.00024296,564.935)">
                      <path
                        id="bottom"
                        d="M390,270L420,270L420,270C420,270 420.195,250.19 405,265C389.805,279.81 390,279.967 390,279.967"
                      />
                    </g>
                    <path id="middle" d="M390,284.967L420,284.967" />
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </header>

      <div
        className={`bg---dark ${isSidebarActive ? "active" : ""}`}
        onClick={handleSlider}
      ></div>

      <div
        className={`sidebar ${isSidebarActive ? "active" : ""}`}
        id="sidebar"
      >
        <a className="res-cross" id="res-cross" onClick={handleSlider}>
          <i className="fa fa-close"></i>
        </a>
        <div className="side-links my-5">
          <a href="#" className="nav-item smooth-menu">
            Home
          </a>
          <a href="#feature" className="nav-item smooth-menu">
            Feature
          </a>
          <a href="#about" className="nav-item smooth-menu">
            About
          </a>
          <a href="#partners" className="nav-item smooth-menu">
            Partners
          </a>
          <a href="#team" className="nav-item smooth-menu">
            Team
          </a>
          <a href="#blog" className="nav-item smooth-menu">
            blog
          </a>
        </div>
      </div>

      <section className="hero-section default-padding">
        <div className="container">
          <h1 className="hero-title wow fadeInDown">{t(`hero.title`)}</h1>
          <p className="hero-desc wow fadeInLeft">{t(`hero.description`)}</p>
          <div className="d-flex" style={{ margin: "30px 0px", gap: "2em" }}>
            <button className="custom-btn bx-btn wow fadeInUp">
              <span>{t(`hero.button`)}</span>
            </button>
            <MagnificPopup />
          </div>
        </div>
      </section>

      <section id="feature" className="feature-area default-padding">
        <div className="container">
          <div className="features-gridx wow fadeInLeft">
            {Feature.map((item, index) => (
              <div className="single-feature" key={index}>
                <img className="img-fluid" src={item.srcImage} alt="" />
                <h4>{i18n.language === "en" ? item.titleEn : item.titleFr}</h4>
                <p>
                  {i18n.language === "en"
                    ? item.descriptionEn
                    : item.descriptionFr}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section id="about" className="default-padding mt-lg-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 order-md-1 order-2 wow fadeInLeft">
              <h2 className="section-title">{t(`about.title`)}</h2>
              <p className="section-description">{t(`about.description`)}</p>
              <ul className="my-4">
                <li>{t(`about.feaOne`)}</li>
                <li>{t(`about.feaTwo`)}</li>
                <li>{t(`about.feaThree`)}</li>
                <li>{t(`about.feaFour`)}</li>
              </ul>
              <button className="custom-btn bx-btn">
                <span>learn more</span>
              </button>
            </div>
            <div className="col-md-6 order-md-2 order-1">
              <figure>
                <img src={About2} alt="lorem" className="wow fadeInUp" />
                <img src={About1} alt="lorem" className="wow fadeInRight" />
              </figure>
            </div>
          </div>
        </div>
      </section>

      <section id="partners" className="default-padding">
        <div className="container">
          <h2 className="section-title text-center wow fadeInUp">
            {t(`partners.title`)}
          </h2>
          <p className="section-description text-center wow fadeInUp">
            {t(`partners.description`)}
          </p>

          {/*  Start OWL Slider */}

          <OwlCarousel className="owl-theme wow fadeInLeft" {...options}>
            {Partners.map((item, index) => (
              <div className="item" key={index}>
                <img src={item.srcImage} alt="" />
              </div>
            ))}
          </OwlCarousel>

          {/*  End OWL Slider */}
        </div>
      </section>

      <section id="video" className="default-padding wow fadeInUp">
        <figure>
          <img src={videoImage} alt="" />
          <MagnificPopup />
        </figure>
      </section>

      <section id="team" className="default-padding">
        <div className="container">
          <h2 className="section-title text-center wow fadeInUp">
            {t(`partners.title`)}
          </h2>
          <p className="section-description text-center wow fadeInUp">
            {t(`team.description`)}
          </p>
          <div
            className="position-relative wow fadeInUp"
            style={{ overflow: "hidden" }}
          >
            {/*  Start Team Slider */}
            <div className="team-slider" id="teamSlider">
              {Team.map((item, index) => (
                <div className="team-member" key={index}>
                  <img src={item.srcImage} alt="" />
                  <div className="main-info">
                    <h4>{item.title}</h4>
                    <p>{item.description}</p>
                    <span className="social-icons">
                      <a href={item.facebook}>
                        <i className="fa-brands fa-facebook-f"></i>
                      </a>
                      <a href={item.telegram}>
                        <i className="fa-brands fa-telegram"></i>
                      </a>
                      <a href={item.instagram}>
                        <i className="fa-brands fa-instagram"></i>
                      </a>
                      <a href={item.youtube}>
                        <i className="fa-brands fa-youtube"></i>
                      </a>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/*  End Team Slider */}
        </div>
      </section>

      <section id="blog" className="blog-section default-padding">
        <div className="container">
          <div className="my-4">
            <h2 className="section-title text-center">{t(`blog.title`)}</h2>
            <p className="section-description text-center">
              {t(`blog.description`)}
            </p>
          </div>
          <div className="row">
            {Blog.map((item, index) => (
              <div className="col-xl-4 col-lg-4 col-md-6" key={index}>
                <div
                  className="single-blog mb-30 wow fadeInUp"
                  data-wow-delay=".6s"
                >
                  <div className="blog-img mb-25">
                    <a href="#">
                      <img src={item.BlogImage} alt="lorem" />
                    </a>
                  </div>
                  <div className="blog-content">
                    <div className="blog-meta mb-20">
                      <div className="blog-author">
                        <img src={item.AuthorImage} alt="lorem" />
                        <span>{item.AuthorName}</span>
                      </div>
                      <div className="blog-comment">
                        <i className="lni lni-comments-alt"></i>
                        <span>{item.numberOfComments}</span>
                      </div>
                    </div>
                    <div className="blog-title">
                      <h4>
                        <a href="#">
                          {i18n.language === "en"
                            ? item.titleEn
                            : i18n.language === "fr"
                            ? item.titleFr
                            : item.titleEn}
                        </a>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section id="contact" className="default-padding-top">
        <div className="section sigma_contact-sec pt-0">
          <div className="container">
            <div className="section-title">
              <h3 className="section-title text-center">
                Contact <span className="primary-color">Us</span>
              </h3>
              <p className="section-description text-center">
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
              </p>
            </div>
            <form
              className="mf_form_validate ajax_submit"
              action="#"
              method="post"
              novalidate="novalidate"
            >
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>First Name</label>
                    <input type="text" name="name" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Last Name</label>
                    <input type="text" name="lname" />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>
                      Email <i className="required">*</i>
                    </label>
                    <input type="email" name="email" />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>
                      Message <i className="required">*</i>
                    </label>
                    <textarea
                      name="message"
                      rows="5"
                      placeholder="message"
                    ></textarea>
                  </div>
                </div>
                <div className="col-12">
                  <button type="submit" className="button">
                    Submit
                  </button>
                  <div className="server_response w-100"></div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="sigma_contact-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.9914406081493!2d2.292292615201654!3d48.85837360866272!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66e2964e34e2d%3A0x8ddca9ee380ef7e0!2sEiffel%20Tower!5e0!3m2!1sen!2sin!4v1571115084828!5m2!1sen!2sin"
            height="600"
            allowfullscreen=""
          ></iframe>
        </div>
        {/* contact-form End */}
      </section>

      <footer className="footer default-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-4 col-xs-12">
              <div className="single_footer">
                <h4 className="wow fadeInUp">{t(`footer.titleOne`)}</h4>
                <ul className="wow fadeInLeft">
                  <li>
                    <a href="#">Lorem Ipsum</a>
                  </li>
                  <li>
                    <a href="#">Simply dummy text</a>
                  </li>
                  <li>
                    <a href="#">The printing and typesetting </a>
                  </li>
                  <li>
                    <a href="#">Standard dummy text</a>
                  </li>
                  <li>
                    <a href="#">Type specimen book</a>
                  </li>
                </ul>
              </div>
            </div>
            {/* END COL */}
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="single_footer single_footer_address">
                <h4 className="wow fadeInUp">{t(`footer.titleTwo`)}</h4>
                <ul className="wow fadeInLeft">
                  <li>
                    <a href="#">Lorem Ipsum</a>
                  </li>
                  <li>
                    <a href="#">Simply dummy text</a>
                  </li>
                  <li>
                    <a href="#">The printing and typesetting </a>
                  </li>
                  <li>
                    <a href="#">Standard dummy text</a>
                  </li>
                  <li>
                    <a href="#">Type specimen book</a>
                  </li>
                </ul>
              </div>
            </div>
            {/* END COL */}
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="single_footer single_footer_address">
                <h4 className="wow fadeInUp">{t(`footer.titleThree`)}</h4>
                <div className="signup_form wow fadeInLeft">
                  <form action="#" className="subscribe">
                    <input
                      type="text"
                      className="subscribe__input"
                      placeholder="Enter Email Address"
                    />
                    <button type="button" className="subscribe__btn">
                      <i className="fas fa-paper-plane"></i>
                    </button>
                  </form>
                </div>
              </div>
              <div className="social_profile">
                <ul>
                  <li>
                    <a href="#" className="wow fadeInRight">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="wow fadeInRight">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="wow fadeInRight">
                      <i className="fab fa-google-plus-g"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="wow fadeInRight">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* END COL */}
          </div>
          {/* END ROW */}
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-xs-12">
              <p className="copyright">
                Copyright © 2023. Designed and Developed by{" "}
                <a href="#" className="text-dark">
                  Achraf Lafkiri
                </a>
              </p>
            </div>
            {/* END COL */}
          </div>
          {/* END ROW */}
        </div>
        {/* END CONTAINER */}
      </footer>

      <ScrollUp />
    </>
  );
};

export default Home;

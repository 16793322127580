import Fea1 from "../assets/images/features/f1.png";
import Fea2 from "../assets/images/features/f2.png";
import Fea3 from "../assets/images/features/f3.png";

const Feature = [
  {
    titleEn: "Online Support",
    titleFr: "Support en Ligne",
    descriptionEn:
      "Lorem ipsum dolor sit amet, consecteturadipis icing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    descriptionFr:
      "Lorem ipsum dolor sit amet, consecteturadipis icing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    srcImage: Fea1,
  },
  {
    titleEn: "24/7 Round the Clock",
    titleFr: "24/7 Tout le Temps",
    descriptionEn:
      "Lorem ipsum dolor sit amet, consecteturadipis icing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    descriptionFr:
      "Lorem ipsum dolor sit amet, consecteturadipis icing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    srcImage: Fea2,
  },
  {
    titleEn: "Handling All Issues",
    titleFr: "Gestion de Tous les Problèmes",
    descriptionEn:
      "Lorem ipsum dolor sit amet, consecteturadipis icing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    descriptionFr:
      "Lorem ipsum dolor sit amet, consecteturadipis icing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    srcImage: Fea3,
  },
];

export default Feature;

import Team1 from "../assets/images/team/team-members-1.png";
import Team2 from "../assets/images/team/team-members-2.png";
import Team3 from "../assets/images/team/team-members-3.png";
import Team4 from "../assets/images/team/team-members-4.png";

const Team = [
  {
    srcImage: Team1,
    title: "Jhon Doe",
    facebook: "#",
    telegram: "#",
    instagram: "#",
    youtube: "#",
  },
  {
    srcImage: Team2,
    title: "Jhon Doe",
    facebook: "#",
    telegram: "#",
    instagram: "#",
    youtube: "#",
  },
  {
    srcImage: Team3,
    title: "Jhon Doe",
    facebook: "#",
    telegram: "#",
    instagram: "#",
    youtube: "#",
  },
  {
    srcImage: Team4,
    title: "Jhon Doe",
    facebook: "#",
    telegram: "#",
    instagram: "#",
    youtube: "#",
  },
];

export default Team;

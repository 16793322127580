import cursorImage from "../assets/images/cursor.png";
import { useEffect, useRef } from "react";
import gsap from "gsap";

const Cursor = () => {
  const dotRef = useRef(null);
  const circleRef = useRef(null);
  const textRef = useRef(null);
  const linkRef = useRef(null);

  let mouse = {
    x: 0,
    y: 0,
  };

  let mouseStored = { ...mouse };

  const setMouseCoords = (event) => {
    mouse.x = event.clientX;
    mouse.y = event.clientY;
  };

  const animateDot = () => {
    if (mouseStored.x === mouse.x && mouseStored.y === mouse.y) return;

    gsap.to(dotRef.current, {
      x: mouse.x,
      y: mouse.y,
      ease: "Power3.easeOut",
      duration: 1.5,
      delay: 0,
    });

    mouseStored.x = mouse.x;
    mouseStored.y = mouse.y;
  };

  const handleMouseEnter = () => {
    gsap.to(linkRef.current, {
      duration: 0.4,
      delay: 0.15,
      scale: 1.125,
      ease: "Power3.easeInOut",
    });
    gsap.to(circleRef.current, {
      duration: 0.4,
      scale: 6,
      fill: "#706fd3",
      ease: "Power3.easeInOut",
    });
    gsap.to(textRef.current, {
      duration: 0.3,
      delay: 0.1,
      opacity: 1,
      ease: "Power3.easeInOut",
    });
  };

  const handleMouseLeave = () => {
    gsap.to(linkRef.current, {
      duration: 0.3,
      scale: 1,
      ease: "Power3.easeOut",
    });
    gsap.to(circleRef.current, {
      duration: 0.3,
      scale: 1,
      delay: 0.1,
      fill: "#474787",
      ease: "Power3.easeOut",
    });
    gsap.to(textRef.current, {
      duration: 0.25,
      opacity: 0,
      ease: "Power3.easeOut",
    });
  };

  const handleMouseMove = (e) => {
    setMouseCoords(e);
  };

  useEffect(() => {
    const dot = dotRef.current;

    gsap.set(dot, { transformOrigin: "50% 50%" });

    window.addEventListener("mousemove", handleMouseMove);
    gsap.ticker.add(animateDot);

    gsap.to(dot, {
      duration: 1,
      delay: 1,
      scale: 1,
      opacity: 1,
      ease: "Power3.easeInOut",
    });

    dot.addEventListener("mouseenter", handleMouseEnter);
    dot.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      gsap.ticker.remove(animateDot);
      dot.removeEventListener("mouseenter", handleMouseEnter);
      dot.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  return (
    <img ref={dotRef} className="dot" src={cursorImage} alt="cursorImage" />
  );
};

export default Cursor;

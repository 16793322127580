import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import WOW from "wowjs";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import i18n from "i18next";
import Loading from "./components/Loading";

const App = () => {
  document.dir = i18n.dir();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  // if (isLoading) {
  // // render a loading spinner or any other loading indicator here
  //   return <Loading />;
  // }

  return (
    <Router>
      <Routes>
        <Route index element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
